<template>
<div style="width:100%; padding: 0.5rem !important">
  <q-form ref="editForm">
    <c-card v-show="!onePage" title="요청상세" class="cardClassDetailForm q-mb-sm q-mt-sm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-show="editable && !disabled && param.sopMocId" 
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="moc"
            mappingType="PUT"
            label="완료" 
            icon="check"
            @beforeAction="completeMoc"
            @btnCallback="completeMocCallback" />
          <c-btn 
            v-show="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="moc"
            mappingType="PUT"
            label="저장" 
            icon="save"
            @beforeAction="saveMoc"
            @btnCallback="saveMocCallback" />
          <c-btn v-show="editable && !disabled && param.sopMocId" label="삭제" icon="remove" @btnClicked="removeMoc" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-8 col-sm-8 col-md-4 col-lg-4 col-xl-4">
          <c-text 
            :required="true" 
            :disabled="disabled"
            :editable="editable"
            label="제목"
            name="mocTitle"
            v-model="moc.mocTitle">
          </c-text>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
          <c-plant 
            :required="true" 
            :disabled="disabled"
            :editable="editable" 
            type="edit" 
            name="plantCd" 
            v-model="moc.plantCd" />
        </div>
        <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :disabled="true"
            :editable="editable"
            codeGroupCd="MOC_TYPE_CD"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="mocTypeCd"
            label="변경구분"
            v-model="moc.mocTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="true"
            label="관리번호"
            name="mocNo"
            v-model="moc.mocNo">
          </c-text>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="true"
            label="발의부서/이름"
            name="initiative"
            v-model="initiative">
          </c-text>
        </div>
      </template>
    </c-card>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
        <c-table
          title="설비별 변경여부 판정 기준"
          :columns="grid.columns"
          :gridHeight="gridHeight"
          :data="moc.checklistEquips"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable&&!disabled"
          :contentsField="contentsField"
          @table-data-change="tableDataChange"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
        <c-table
          title="정비·운전 변경여부 판정 기준"
          :columns="grid2.columns"
          :gridHeight="gridHeight"
          :data="moc.checklistDrives"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable&&!disabled"
          :contentsField="contentsField"
          @table-data-change="tableDataChange"
        >
        </c-table>
      </div>
    </div>
  </q-form>
</div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'judge-check-list',
  props: {
    param: {
      type: Object,
      default: () => ({
        sopMocId: '',
        mocTypeCd: '',
        mocStepCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
    onePage: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'mocCheckItemName',
            field: 'mocCheckItemName',
            label: '변경요소',
            align: 'center',
            sortable: true,
            style: 'width:130px',
          },
        ],
        filterData: [],
        saveData: {
          sopMocId: '',
          checklists: [],
        },
        itemCds: [],
        // height: '362px'
      },
      grid2: {
        columns: [
          {
            name: 'mocCheckItemName',
            field: 'mocCheckItemName',
            label: '변경요소',
            align: 'center',
            sortable: true,
            style: 'width:130px',
          },
        ],
        filterData: [],
        saveData: {
          sopMocId: '',
          checklists: [],
        },
        itemCds: [],
      },
      moc: {
        sopMocId: '',  // MOC 일련번호
        mocNo: '',  // moc 관리번호
        plantCd: '',  // 사업장 코드
        initiativeUserId: '',  // 발의자 ID
        initiativeUserName: '',  // 발의자 명
        initiativeDeptCd: '',  // 발의 부서 코드
        initiativeDeptName: '',  // 발의 부서명
        mocTitle: '',  // 변경관리 제목
        mocSummary: '',  // 변경 개요
        mocTypeCd: 'MT00000001',  // 변경구분_공통코드(정상/비상/임시)
        mocWriteLevelCd: null,  // 변경등급_작성
        mocConfirmLevelCd: null,  // 변경등급_검토
        processSatefySecureMeasures: '',  // 공정안전 확보 대책
        technicalBasisProcessDesign: '',  // 변경계획에 대한 공정 및 설계의 기술근거
        safetyReliabilityImprovementEffect: '',  // 안전성에 필요한 사항 및 신뢰성 향상 효과
        leaderOpinion: '',  // 팀장의견
        relatedLaws: '',  // 관련 볍령_텍스트
        processRiskAssessFlag: '',  // 공정위험성 평가 여부
        jobRiskAssessFlag: '',  // 작업위헝성 평가 여부
        mocPeriod: [],
        mocStartDate: '',  // 변경 시작일
        mocEndDate: '',  // 변경 종료일
        mocStepCd: '',  // 변경관리 단계
        mocActionDeptCd: '',  // 변경실행 부서코드
        mocActionResponsibilityUserId: '',  // 변경실행 책임자 ID
        mocCompleteCheckUserId: '',  // 변경완료 확인자 ID
        mocCompleteCheckDeptCd: '',  // 변경완료 확인 부서 코드
        mocCompleteOpinion: '',  // 변경완료 의견
        mocBenefitCds: '',  // 변경관리 기대효과_복수
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        checklists: [],
        checklistEquips: [],
        checklistDrives: [],
      },
      editable: true,
      baseUrl: '',
      detailUrl: '',
      saveUrl: '',
      completeUrl: '',
      deleteUrl: '',
      isSave: false,
      isComplete: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      let heightNum = this.$_.replace(this.height, 'px', '')
      return heightNum ? (Number(heightNum) - 180) + 'px' : 'auto'
    },
    disabled() {
      // 비상변경에 대해서는 생각하지 않음
      let stepCheck = this.param.mocTypeCd === 'MT00000001' ? 'MS00000000' : 'MT00000000'
      return this.param.mocStepCd !== stepCheck && Boolean(this.param.sopMocId)
    },
    initiative() {
      return this.moc.initiativeDeptName + ' / ' + this.moc.initiativeUserName
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.moc.change.judgeCheckList.get.url
      this.baseUrl = selectConfig.sop.moc.change.judgeCheckList.base.url
      this.saveUrl = transactionConfig.sop.moc.checklist.save.url
      this.completeUrl = transactionConfig.sop.moc.change.complete.url
      this.deleteUrl = transactionConfig.sop.moc.change.delete.url
      // code setting
      this.$comm.getComboItems('MOC_CHECK_TYPE_CD').then(_result => {
        let columns1 = this.$_.filter(_result, { attrVal1: 'equip' })
        let columns2 = this.$_.filter(_result, { attrVal1: 'check-dirve' })

        this.grid.itemCds = this.$_.map(columns1, 'code');
        this.grid2.itemCds = this.$_.map(columns2, 'code');

        if (columns1 && columns1.length > 0) {
          this.$_.forEach(columns1, column => {
            this.grid.columns.push({
              name: column.code,
              field: column.code,
              label: column.codeName,
              align: 'center',
              sortable: true,
              style: 'width:' + String(30 + (column.codeName.length * 10)) + 'px',
              type: 'check',
              true: 'Y',
              false: 'N',
            })
          })
        }
        if (columns2 && columns2.length > 0) {
          this.$_.forEach(columns2, column => {
            this.grid2.columns.push({
              name: column.code,
              field: column.code,
              label: column.codeName,
              align: 'center',
              sortable: true,
              style: 'width:' + String(30 + (column.codeName.length * 10)) + 'px',
              type: 'check',
              true: 'Y',
              false: 'N',
            })
          })
        }
      });
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.sopMocId) {
        this.$http.url = this.$format(this.detailUrl, this.param.sopMocId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.moc, _result.data);
        },);
      } else {
        this.$http.url = this.baseUrl;
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.moc, _result.data);
          this.moc.plantCd = this.$store.getters.user.plantCd
          this.moc.initiativeUserId = this.$store.getters.user.userId
          this.moc.initiativeUserName = this.$store.getters.user.userName
          this.moc.initiativeDeptCd = this.$store.getters.user.deptCd
          this.moc.initiativeDeptName = this.$store.getters.user.deptName
          this.moc.regUserId = this.$store.getters.user.userId
          this.moc.mocTypeCd = this.param.mocTypeCd
          this.moc.mocStepCd = this.param.mocTypeCd === 'MT00000001' ? 'MS00000000' : 'MT00000000';
        },);
      }
    },
    contentsField(props, col) {
      let returnVal = true;
      if (col.name !== 'mocCheckItemName') {
        returnVal = props.row[col.name + '_use_flag'] === 'Y'
      }
      return returnVal
    },
    tableDataChange(props, col) {
      if (props.row[col.name + '_edit_flag'] !== 'C') {
        props.row[col.name + '_edit_flag'] = 'U'
      }
    },
    saveMoc() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result 
          && this.$comm.validTable(this.grid.columns, this.moc.checklistEquips)
          && this.$comm.validTable(this.grid2.columns, this.moc.checklistDrives)) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.moc.regUserId = this.$store.getters.user.userId
              this.moc.chgUserId = this.$store.getters.user.userId

              /**
               * 설비별 변경여부 판정 기준
               */
              this.moc.checklists = [];
              this.$_.forEach(this.moc.checklistEquips, item => {
                for (let key in item) {
                  if (this.$_.indexOf(this.grid.itemCds, key) > -1) {
                    this.moc.checklists.push({
                      sopMocId: this.param.sopMocId,  // MOC 일련번호
                      mocCheckTypeCd: key,  // 체크 구분 코드
                      mocCheckItemCd: item.mocCheckItemCd,  // 체크 항목 코드
                      mocChecklistTypeCd: 'MCT0000001',  // 체크리스트 타입 코드
                      checkFlag: item[key],  // 사용 여부
                      regUserId: this.$store.getters.user.userId,  // 등록자 ID
                      chgUserId: this.$store.getters.user.userId,  // 수정자 ID
                      editFlag: item[key + '_edit_flag']
                    })
                  }
                }
              })

              /**
               * 정비운전 변경여부 판정 기준
               */
              this.$_.forEach(this.moc.checklistDrives, item => {
                for (let key in item) {
                  if (this.$_.indexOf(this.grid2.itemCds, key) > -1) {
                    this.moc.checklists.push({
                      sopMocId: this.param.sopMocId,  // MOC 일련번호
                      mocCheckTypeCd: key,  // 체크 구분 코드
                      mocCheckItemCd: item.mocCheckItemCd,  // 체크 항목 코드
                      mocChecklistTypeCd: 'MCT0000005',  // 체크리스트 타입 코드
                      checkFlag: item[key],  // 사용 여부
                      regUserId: this.$store.getters.user.userId,  // 등록자 ID
                      chgUserId: this.$store.getters.user.userId,  // 수정자 ID
                      editFlag: item[key + '_edit_flag']
                    })
                  }
                }
              })

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveMocCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.param.sopMocId = _result.data
      this.param.mocStepCd = this.param.mocTypeCd === 'MT00000001' ? 'MS00000000' : 'MT00000000'
      this.$emit('emitStep', {
        name: 'keySetting',
        param: _result.data
      })
      this.getDetail();
    },
    completeMoc() {
      // 판정이 하나라도 이루어 졌는지?
      let isProgress = true;
      if (this.moc.checklistEquips && this.moc.checklistEquips.length > 0) {
        let isChecklist = false;
        this.$_.forEach(this.moc.checklistEquips, item => {
          for (let key in item) {
            if (this.$_.indexOf(this.grid.itemCds, key) > -1 && item[key] === 'Y') {
              isChecklist = true
              break;
            }
          }
          if (isChecklist) {
            return false;
          }
        })
        if (!isChecklist) {
          this.$_.forEach(this.moc.checklistDrives, item => {
            for (let key in item) {
              if (this.$_.indexOf(this.grid2.itemCds, key) > -1 && item[key] === 'Y') {
                isChecklist = true
                break;
              }
            }
            if (isChecklist) { 
              return false;
            }
          })
          if (!isChecklist) {
            isProgress = false;
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message: '판정이 이루어지지 않았습니다.\n\r체크리스트 중 하나 이상 판정을 해주십시오.',
              type: 'warning', // success / info / warning / error
            });
          }
        }
      } 

      if (isProgress) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '완료하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.moc.chgUserId = this.$store.getters.user.userId
            this.moc.mocStepCd = (this.param.mocTypeCd === 'MT00000001' ? 'MS00000001' : 'MT00000001')

            this.isComplete = !this.isComplete
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    completeMocCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // 계획 상세 조회
      this.getDetail();
      // stepper 이동
      this.$emit('emitStep', {
        name: 'stepBystep',
        param: this.moc.mocStepCd
      })
    },
    removeMoc() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.param.sopMocId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('emitStep', {
              name: 'closePopup'
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    }
  }
};
</script>
