var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "100%", padding: "0.5rem !important" } },
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.onePage,
                  expression: "!onePage",
                },
              ],
              staticClass: "cardClassDetailForm q-mb-sm q-mt-sm",
              attrs: { title: "요청상세" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.editable &&
                              !_vm.disabled &&
                              _vm.param.sopMocId,
                            expression:
                              "editable && !disabled && param.sopMocId",
                          },
                        ],
                        attrs: {
                          isSubmit: _vm.isComplete,
                          url: _vm.completeUrl,
                          param: _vm.moc,
                          mappingType: "PUT",
                          label: "완료",
                          icon: "check",
                        },
                        on: {
                          beforeAction: _vm.completeMoc,
                          btnCallback: _vm.completeMocCallback,
                        },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable && !_vm.disabled,
                            expression: "editable && !disabled",
                          },
                        ],
                        attrs: {
                          isSubmit: _vm.isSave,
                          url: _vm.saveUrl,
                          param: _vm.moc,
                          mappingType: "PUT",
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveMoc,
                          btnCallback: _vm.saveMocCallback,
                        },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.editable &&
                              !_vm.disabled &&
                              _vm.param.sopMocId,
                            expression:
                              "editable && !disabled && param.sopMocId",
                          },
                        ],
                        attrs: { label: "삭제", icon: "remove" },
                        on: { btnClicked: _vm.removeMoc },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-xs-8 col-sm-8 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        label: "제목",
                        name: "mocTitle",
                      },
                      model: {
                        value: _vm.moc.mocTitle,
                        callback: function ($$v) {
                          _vm.$set(_vm.moc, "mocTitle", $$v)
                        },
                        expression: "moc.mocTitle",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-plant", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        type: "edit",
                        name: "plantCd",
                      },
                      model: {
                        value: _vm.moc.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.moc, "plantCd", $$v)
                        },
                        expression: "moc.plantCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-select", {
                      attrs: {
                        disabled: true,
                        editable: _vm.editable,
                        codeGroupCd: "MOC_TYPE_CD",
                        type: "edit",
                        itemText: "codeName",
                        itemValue: "code",
                        name: "mocTypeCd",
                        label: "변경구분",
                      },
                      model: {
                        value: _vm.moc.mocTypeCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.moc, "mocTypeCd", $$v)
                        },
                        expression: "moc.mocTypeCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: true,
                        label: "관리번호",
                        name: "mocNo",
                      },
                      model: {
                        value: _vm.moc.mocNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.moc, "mocNo", $$v)
                        },
                        expression: "moc.mocNo",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: true,
                        label: "발의부서/이름",
                        name: "initiative",
                      },
                      model: {
                        value: _vm.initiative,
                        callback: function ($$v) {
                          _vm.initiative = $$v
                        },
                        expression: "initiative",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-7" },
              [
                _c("c-table", {
                  attrs: {
                    title: "설비별 변경여부 판정 기준",
                    columns: _vm.grid.columns,
                    gridHeight: _vm.gridHeight,
                    data: _vm.moc.checklistEquips,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    editable: _vm.editable && !_vm.disabled,
                    contentsField: _vm.contentsField,
                  },
                  on: { "table-data-change": _vm.tableDataChange },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-5" },
              [
                _c("c-table", {
                  attrs: {
                    title: "정비·운전 변경여부 판정 기준",
                    columns: _vm.grid2.columns,
                    gridHeight: _vm.gridHeight,
                    data: _vm.moc.checklistDrives,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    editable: _vm.editable && !_vm.disabled,
                    contentsField: _vm.contentsField,
                  },
                  on: { "table-data-change": _vm.tableDataChange },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }